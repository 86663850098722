// @flow
import React from 'react';
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-google-gtag';

import styles from './Footer.module.scss';
import { trackAppDownload } from '../../utils';

const Footer = () => {
  return (
    <div className={styles.footerWrapper}>
      <footer>
        <nav>
          <div className={styles.col}>
            <span className={styles.title}>あと払いペイディ</span>
            <Link to="/payments/">使い方</Link>
            <Link to="/shop/">使えるお店</Link>
            <Link to="/npay/">3・6・12回あと払い</Link>
            <Link to="/paidycard/">ペイディカード</Link>
            <Link to="/apple/">
              ペイディあと払いプラン
              <br />
              Apple専用
            </Link>
          </div>
          <div className={styles.col}>
            <span className={styles.title}>お役立ち情報</span>
            <Link to="/guide/">ご利用ガイド</Link>
            <Link to="/campaign/">キャンペーン</Link>
            <Link to="/column/">お知らせ</Link>
            <Link to="/security/">安全への取り組み</Link>
            <OutboundLink
              title="Paidy | お問い合わせ"
              href="https://cs-support.paidy.com/support/home"
              target="_blank"
              rel="noopener noreferrer"
            >
              よくあるご質問
            </OutboundLink>
          </div>
          <div className={styles.col}>
            <span className={styles.title}>企業情報</span>
            <Link to="https://corp.paidy.com/about/">会社プロフィール</Link>
            <Link to="https://corp.paidy.com/news/">プレスリリース</Link>
            <Link to="https://corp.paidy.com/recruit/">採用情報</Link>
            <Link to="https://corp.paidy.com/hr-blog/">HRブログ</Link>
            <OutboundLink
              title="Paidy | 利用規約"
              href="https://terms.paidy.com"
              target="_blank"
              rel="noopener noreferrer"
            >
              利用規約・個人情報保護
            </OutboundLink>
          </div>
          <div className={styles.col}>
            <span className={styles.title}>導入について</span>
            <Link to="/merchant/application/">導入の流れ</Link>
            <Link to="/merchant/#case_study/">導入事例</Link>
            <Link to="/merchant/application/#documents/">導入申込み</Link>
            <OutboundLink
              title="Paidy | 技術ドキュメント"
              href="https://paidy.com/docs/jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              技術ドキュメント
            </OutboundLink>
          </div>
          <div className={styles.col}>
            <span className={styles.title}>法人のお客様</span>
            <Link to="/merchant/">法人のお客様</Link>
            <OutboundLink
              title="Paidy | 加盟店ログイン"
              href="https://merchant.paidy.com/login"
              target="_blank"
              rel="noopener noreferrer"
            >
              加盟店ログイン
            </OutboundLink>
            <Link to="/merchant/contact_us/">法人/PRお問合せ</Link>
          </div>
        </nav>
        <section className={styles.misc}>
          <Link to="/">
            <img
              className={styles.logo}
              src={require('../../images/logo-white.svg')}
              alt="Paidy"
              loading="lazy"
            />
          </Link>
          <div>
            <OutboundLink
              className={styles.icon}
              href="https://twitter.com/paidyjp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../images/icon-x-white.svg')}
                alt="Paidy | Twitter"
              />
            </OutboundLink>
            <OutboundLink
              className={styles.icon}
              href="https://www.linkedin.com/company/paidy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src={require('../../images/icon-linkedin-2.svg')}
                alt="Paidy | Linkedin"
              />
            </OutboundLink>
            <div className={styles.apps}>
              <a
                title="Paidy | App Store"
                className={styles.app}
                href="https://paidy.onelink.me/eV85/PCfooter"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackAppDownload('App Store', 'footer')}
              >
                <img
                  src={require('../../images/badge-app-store.svg')}
                  alt="Paidy | App Store"
                />
              </a>
              <a
                title="Paidy | Google Play"
                className={styles.app}
                href="https://paidy.onelink.me/KTFT/PCfooter"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => trackAppDownload('Google Play', 'footer')}
              >
                <img
                  src={require('../../images/badge-google-play.svg')}
                  alt="Paidy | Google Play"
                />
              </a>
            </div>
            <span className={styles.credit}>&copy; Paidy Inc.</span>
          </div>
        </section>
      </footer>
    </div>
  );
};

Footer.defaultProps = { isMerchant: false };

export default Footer;
